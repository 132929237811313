import * as Entities from './pas-cart-event-log-type.g';

/**
 * Entity class for event log
 */
export class PasCartEventLogClass implements Entities.ICartEventLogEntity {
    public CartId: string;
    public CustAccount: string;
    public Channel: number;

    // tslint:disable-next-line: no-any
    constructor(odataObject?: any) {
        odataObject = odataObject || {};

        this.CartId = odataObject.CartId;
        this.CustAccount = odataObject.CustAccount;
        this.Channel = odataObject.Channel;
    }
}
