import { createDataActionHook, IActionContext, IActionInput } from '@msdyn365-commerce/core';
import { Cart, IContext, SalesOrder } from '@msdyn365-commerce/retail-proxy';
import { createCartEventLogAsync } from '../actions/log-updated-cart-event.action';
import { CartEventType } from '../entities/pas-cart-event-log-type.g';

const createCartEventLog = (cart: Cart | Cart[], actionContext: IActionContext, eventType: CartEventType) => {
    if (cart && !Array.isArray(cart) && cart.CustomerId) {
        const context: IContext = {
            callerContext: {
                requestContext: actionContext.requestContext
            }
        };
        createCartEventLogAsync(context, {
            CustAccount: cart.CustomerId,
            CartId: cart.Id,
            Channel: cart.ChannelId,
            EventType:
                Array.isArray(cart.CartLines) && cart.CartLines.length === 0
                    ? CartEventType[CartEventType.Deleted]
                    : CartEventType[eventType]
        });
    }
};

const afterAddCartLines = async (_inputs: IActionInput | IActionInput[], cart: Cart | Cart[], actionContext: IActionContext) => {
    createCartEventLog(cart, actionContext, CartEventType.Updated);
};
const afterRemoveCartLines = async (_inputs: IActionInput | IActionInput[], cart: Cart | Cart[], actionContext: IActionContext) => {
    createCartEventLog(cart, actionContext, CartEventType.Updated);
};
const afterUpdateCartLines = async (_inputs: IActionInput | IActionInput[], cart: Cart | Cart[], actionContext: IActionContext) => {
    createCartEventLog(cart, actionContext, CartEventType.Updated);
};
const afterCheckoutCart = async (
    inputs: IActionInput | IActionInput[],
    salesOrder: SalesOrder | SalesOrder[],
    actionContext: IActionContext
) => {
    if (salesOrder && !Array.isArray(salesOrder) && salesOrder.CustomerId) {
        const context: IContext = {
            callerContext: {
                requestContext: actionContext.requestContext
            }
        };
        createCartEventLogAsync(context, {
            CustAccount: salesOrder.CustomerId,
            CartId: salesOrder.Id,
            Channel: salesOrder.ChannelId,
            EventType: CartEventType[CartEventType.CheckedOut]
        });
    }
};

createDataActionHook({
    actionId: '@msdyn365-commerce/retail-proxy/Carts/AddCartLines',
    postReadonlyHook: afterAddCartLines
});
createDataActionHook({
    actionId: '@msdyn365-commerce/retail-proxy/Carts/RemoveCartLines',
    postReadonlyHook: afterRemoveCartLines
});

createDataActionHook({
    actionId: '@msdyn365-commerce/retail-proxy/Carts/UpdateCartLines',
    postReadonlyHook: afterUpdateCartLines
});

createDataActionHook({
    actionId: '@msdyn365-commerce/retail-proxy/Carts/Checkout',
    postHook: afterCheckoutCart
});
