export interface ICartEventLogEntity {
    Id?: number;
    CartId: string;
    CustAccount?: string;
    Channel?: number;
    EventType?: string;
    CartModifiedDate?: Date;
}

export enum CartEventType {
    None,
    Created,
    Updated,
    CheckedOut,
    Deleted
}
