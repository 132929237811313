import { AsyncResult } from '@msdyn365-commerce/retail-proxy/dist/async-result';
import { DataServiceQuery } from '@msdyn365-commerce/retail-proxy/dist/DataServiceQuery';
import { IContext, IDataServiceRequest } from '@msdyn365-commerce/retail-proxy/dist/Interfaces';
import { executeAction } from '@msdyn365-commerce/retail-proxy/dist/retail-action';
import { PasCartEventLogClass } from '../entities/pas-cart-event-log-models';
import { ICartEventLogEntity } from '../entities/pas-cart-event-log-type.g';

function cartEventLogQuery(id?: string): DataServiceQuery {
    const key = id ? { Id: id } : null;
    return new DataServiceQuery('CartEventLog', 'CartEventLog', PasCartEventLogClass, key);
}

export function createCreateInput(entity: ICartEventLogEntity): IDataServiceRequest {
    return cartEventLogQuery().create(entity);
}

export function createCartEventLogAsync(context: IContext, entity: ICartEventLogEntity): AsyncResult<ICartEventLogEntity> {
    const request = createCreateInput(entity);
    return executeAction(request, context.callerContext);
}
