import * as Entities from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceModels.g';

/**
 * PasProductSearchResultClass
 */
export class PasProductSearchResultClass extends Entities.ProductSearchResultClass {
    public static IS_PRODUCT_MASTER_FIELD_KEY: string = 'PAS_IS_PRODUCT_MASTER';

    // tslint:disable-next-line: no-any
    constructor(odataObject?: any) {
        super(odataObject);

        if (!this.ExtensionProperties) {
            this.ExtensionProperties = [];
        }

        const isProductMasterExtensionField = new Entities.CommercePropertyClass();
        isProductMasterExtensionField.Key = PasProductSearchResultClass.IS_PRODUCT_MASTER_FIELD_KEY;
        const value = new Entities.CommercePropertyValueClass();
        value.BooleanValue = odataObject.IsMasterProduct;
        isProductMasterExtensionField.Value = value;
        this.ExtensionProperties.push(isProductMasterExtensionField);
    }
}
