import { IPasCartNotification, IPasCartNotificationType } from './pas-cart-notification-type.g';

/**
 * PasProductSearchResultClass
 */
export class PasCartNotificationClass implements IPasCartNotification {
    public message?: string;
    public type?: IPasCartNotificationType;

    // tslint:disable-next-line: no-any
    constructor(odataObject?: any) {
        odataObject = odataObject || {};
        this.message = odataObject.Message;
        this.type = IPasCartNotificationType[<string>odataObject.Type];
    }
}
